<template>
    <div>
        <el-tabs type="card" v-model="activeCode" @tab-click="getDataList">
            <el-tab-pane v-for="item in tableData" :key="item.code" :name="item.code" :label="item.name">
                <el-button type="primary" @click="addOrUpdateHandle(colsData)">添加</el-button>
                
            </el-tab-pane>
        </el-tabs>
        <!-- {{dataList}} -->
        <el-table v-loading="dataListLoading" :data="dataList">
            <el-table-column prop="name" label="名称"></el-table-column>
            <el-table-column v-for="col in colsData.cols" :key="col.prop" :prop="'data.'+col.prop" :label="col.label">
                <template slot-scope="scope">
                  <img v-if="col.prop == 'icon'" style="width:26px" :src="$imgPath+scope.row.data.icon" />
                  <img v-else-if="col.prop == 'img'" style="height:50px" :src="$imgPath+scope.row.data.img" />
                  <!-- 二级菜单的按钮数量 -->
                  <span v-else-if="col.prop == 'items'">{{scope.row.data.items && scope.row.data.items.length}}</span>
                  <!-- 分类页面的页面 -->
                  <span v-else-if="col.prop.indexOf('page') == 0 || col.prop == 'deviceType'">
                    {{scope.row.data[col.prop] && scope.row.data[col.prop].title}}</span>
                  <span v-else-if="col.type == 'boolean'">{{scope.row.data[col.prop]?'是':'否'}}</span>
                  <span v-else-if="col.type == 'btnClazz'">
                    {{scope.row.data[col.prop]==1?'一级按钮':scope.row.data[col.prop]==2?'二级按钮':scope.row.data[col.prop]==3?'自定义二级':''}}
                  </span>
                  <span v-else>{{String(scope.row.data[col.prop] || '')}}</span>
                </template>
            </el-table-column>
            <el-table-column label="操作">
                <template slot-scope="scope">
                    <el-button size="mini" @click="addOrUpdateHandle(colsData,scope.row.id)">编辑</el-button>
                    <el-button size="mini" type="danger" @click="del(scope.row.id)" >删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <!-- 弹窗, 新增 / 修改 -->
        <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
    </div>
</template>
<script>
import AddOrUpdate from './settingData-add-or-update'
import Sortable from 'sortablejs'
import clone from 'lodash/clone'
export default {
  components: {
    AddOrUpdate
  },
  data() {
    return {
      baseData: [],
      addOrUpdateVisible: false,
      activeCode: 'classPage',
      tableData: [
        // {
        //   code: 'supportSystem',
        //   name: '支持系统',
        //   cols: [{prop: 'BAUD', label: '波特率'}, {prop: 'enableReceivedCommand', label: '轮询码'}, {prop: 'wakeupToHomeCommand', label: '唤醒值'}]
        // },
        {
          code: 'classPage',
          name: '分类页面',
          cols: [{prop: 'page1', label: '第1页', type: 'dt'}, {prop: 'page2', label: '第2页', type: 'dt'}, {prop: 'page3', label: '第3页', type: 'dt'}, {prop: 'page4', label: '第4页', type: 'dt'}, {prop: 'page5', label: '第5页', type: 'dt'}]
        }, {
          code: 'deviceType',
          name: '设备类别',
          cols: [{prop: 'icon', label: '图标', type: 'img'}, {prop: 'value', label: '值'}]
        }, {
          code: 'commandName',
          name: '按钮名称',
          cols: [{prop: 'icon', label: '图标', type: 'img'}, {prop: 'value', label: '值'}, {prop: 'deviceType', label: '所属类别', type: 'dt'}]
        }, {
          code: 'keyType',
          name: '按钮类型',
          cols: [{prop: 'value', label: '类型'}, {prop: 'clazz', label: '类别', type: 'btnClazz'},{prop: 'isGjcj', label: '高级场景', type: 'boolean'}]
        }, {
          code: 'senior',
          name: '高级协议',
          cols: [{prop: 'value', label: '数据', type: 'textarea'}, {prop: 'remark', label: '备注'}]
        }, {
          code: 'room',
          name: '房间数据',
          cols: []
        }, {
          code: 'wakeupToHomeCommand',
          name: '唤醒值',
          cols: [{prop: 'value', label: '值', type: 'byteCode'}, {prop: 'supportSystem', label: '所属系统', type: 'sys'}]
        }, {
          code: 'checkALG',
          name: '效验算法',
          cols: [{prop: 'code', label: '标识'}, {prop: 'desc', label: '详情说明'}]
        }
        // {
        //   code: 'commandBytes',
        //   name: '按钮数据',
        //   cols: [{prop: 'supportSystem', label: '所属系统', type: 'sys'}, {prop: 'commandBytes', label: '控制码'}, {prop: 'feedback0', label: '反馈值关'}, {prop: 'feedback1', label: '反馈值开'}]
        // }, {
        //   code: 'menus',
        //   name: '二级菜单',
        //   cols: [{prop: 'img', label: '图片'}, {prop: 'feedback0', label: '反馈值关'}, {prop: 'feedback1', label: '反馈值开'}, {prop: 'items', label: '按钮数量', type: 'menu'}]
        // }, {
        //   code: 'url',
        //   name: '按钮数据2',
        //   cols: [{prop: 'value', label: '值'}]
        // }
      ],
      dataListLoading: false,
      dataList: [],
      sortDataList: []
    }
  },
  computed: {
    colsData: function() {
      return this.tableData.find(item => item.code === this.activeCode)
    }
  },
  methods: {
    // 新增 / 修改
    addOrUpdateHandle (colsData, id) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(colsData, id)
      })
    },
    del(id) {
      this.$confirm('是否确定删除配制？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl('/bus/busdata/delete'),
          method: 'post',
          data: this.$http.adornData([id], false)
        }).then(({data}) => {
          if (data && data.code === 0) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1500,
              onClose: () => {
                this.getDataList()
              }
            })
          } else {
            this.$message.error(data.msg)
          }
        })
      })
    },
    // 获取数据列表
    getDataList () {
      this.dataListLoading = true
      this.$http({
        url: this.$http.adornUrl('/bus/busdata/list'),
        method: 'get',
        params: this.$http.adornParams({
          'page': 1,
          'limit': 99999,
          'code': this.activeCode
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          for (var row of data.page.list) {
            row.data = JSON.parse(row.data)
          }
          this.dataList = data.page.list
          console.log(this.dataList)
        } else {
          this.dataList = []
        }
        this.sortDataList = clone(this.dataList)
        this.dataListLoading = false
        this.$nextTick(() => {
          this.setSort()
        })
      })
    },
    setSort() {
      const el = document.querySelectorAll('.el-table__body-wrapper > table > tbody')[0]
      Sortable.create(el, {
        ghostClass: 'sortable-ghost', // Class name for the drop placeholder,
        options: { forceFallback: true },
        onEnd: evt => {
          const targetRow = this.sortDataList.splice(evt.oldIndex, 1)[0]
          this.sortDataList.splice(evt.newIndex, 0, targetRow)
          var ids = this.sortDataList.map(item => item.id)
          console.log(evt.newIndex)
          console.log(evt.oldIndex)
          console.log(ids)
          this.$http({
            url: this.$http.adornUrl('/bus/busdata/sort'),
            method: 'post',
            data: this.$http.adornData(ids, false)
          }).then(({data}) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
                onClose: () => {
                }
              })
            } else {
              this.$message.error(data.msg)
            }
          })
        }
      })
    }
  },
  mounted() {
    this.getDataList()
  }
}
</script>
<style scoped lang="scss">
.sortable-ghost{
  opacity: .8;
  color: #fff!important;
  background: #42b983!important;
}
.el-table ::v-deep .cell{
  max-height: 90px;
}
</style>